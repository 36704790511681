<template>
  <div>
      <div>
        <span style="color: rgba(0, 0, 0, 0.85);font-weight: bold;font-size: 16px;margin-bottom: 10px;display: inline-block;">开票明细</span>
        <a-button @click="add" style="margin-bottom: 10px;margin-left: 10px;" v-if="!this.$route.query.isDetail && !this.$route.query.status">增行</a-button>
    </div>
  <!-- <a-table :columns="this.$route.query.isDetail ? columns1 : columns" :data-source="data" :pagination="false" bordered :scroll="{x:1500}"> -->
  <a-table :columns="this.$route.query.isDetail ? columns1 : this.$route.query.status ? columns1 : columns" :data-source="data" :pagination="false" bordered :scroll="{x:1500}">
      <template
      v-for="col in [
          'contentId',
          'unit',
          'number',
          'unitPrice',
          'money',
          'taxRate',
          'taxAmount',
          'operation',
          'model'
      ]"
      :slot="col"
      slot-scope="text, record" >

      <div :key="col">
          <a-select
          v-if="record.editable && col == 'contentId'" show-search
          :filter-option="
              (input, option) => {
              return (
                  option.componentOptions.children[0].text
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              );
              }
          "
            placeholder="请选择项目名称"
          style="width: 300px"
          :default-value="null"
          option-label-prop="label"
          @change="(value) => {const selectedItemIndex = sellerList.findIndex(item => item.id === value); selectedItem = sellerList[selectedItemIndex];selectChange(value, record.frontKey, selectedItem);}" >
              <a-select-option style="width: 300px" :value="item.id" :label="item.name"
              v-for="(item, index) in sellerList" :key="index">
                  {{ item.name }}
              </a-select-option>
          </a-select>
          <!-- 规格 -->
          <a-select v-if="record.editable && col == 'model'" show-search
          style="width: 250px"
          :default-value="modelList[0]"
          option-label-prop="label"
          mode="tags"
          @change="(value) => {selectModelChange(value, record.frontKey);}" >
              <a-select-option style="width: 250px" :value="item" :label="item" 
              v-for="(item, index) in modelList" :key="index">
                  {{ item }}
              </a-select-option>
          </a-select>
          <a-input placeholder="请输入" v-if="record.editable && (col == 'unit' || col == 'number' || col == 'money' )" style="margin: -5px 0" :value="text" @change="(e) => handleChange(e.target.value, record.frontKey, col)" />
          <!--金额（含税）税率 税额-->
          <template v-if="col == 'taxRate'&&text">{{ text }}%</template>
          <template v-if="col == 'unitPrice' && record.money && record.number">{{formatAmount(record.money /record.number) }}</template>
          <template v-if="col == 'unitPrice' &&( !record.money|| !record.number)">0.00</template>
          <template v-if="col == 'taxAmount'">{{ (record.money  * (record.taxRate / 100)).toFixed(2)}}</template>
          <template v-if="!record.editable && (col == 'unit' || col == 'number' || col == 'money' )" >
              {{ text }}
          </template>
          <template v-if="!record.editable && col == 'contentId'">
              <a-select
                  style="width: 300px"
                  option-label-prop="label"
                  :value="text"
                  disabled
              >
                  <a-select-option
                  style="width: 300px"
                  :value="item.id"
                  :label="item.name"
                  v-for="(item, index) in sellerList"
                  :key="index"
                  >
                  {{ item.name }}
                  </a-select-option>
              </a-select>
          </template>
          <template v-if="!record.editable && col == 'model'">
              <a-select
                  style="width: 250px"
                  option-label-prop="label"
                  :value="text"
                  disabled
              >
                  <a-select-option
                  style="width: 250px"
                  :value="item"
                  :label="item"
                  v-for="(item, index) in modelList"
                  :key="index"
                  >
                  {{ item }}
                  </a-select-option>
              </a-select>
          </template>
      </div>
      </template>
      <template slot="operation" slot-scope="text, record">
      <div class="editable-row-operations" >
            <span v-if="record.editable ">
              <a @click="() => save(record.frontKey)">保存</a>
              <a @click="() => del(record.frontKey)" style="margin-left: 10px;">删除</a>
              </span>
              <span v-else>
              <a :disabled="editingKey !== '' " @click="() => editCol(record.frontKey)">编辑</a>
            </span>
      </div>
      </template>
  </a-table>

 
  </div>

</template>

<script>
import { request } from "@/utils/request";
const data = [];
data.push({
  frontKey: 1,
  contentId: "",
  unit: "",
  number: "",
  unitPrice: "",
  money: "",
  taxRate: "",
  taxAmount: "",
  editable: true,
});
export default {
data() {
  this.cacheData = this.data.map((item) => ({ ...item }));
  return {
      columns: [ {
          title: "项目名称",
          dataIndex: "contentId",
          scopedSlots: { customRender: "contentId" },
          align: "center",
          width:  330,
        },
        
        {
          title: "规格",
          dataIndex: "model",
          scopedSlots: { customRender: "model" },
          align: "center",
          width: 280,
        },
        {
          title: "单位",
          dataIndex: "unit",
          scopedSlots: { customRender: "unit" },
          align: "center",
          width: "12%",
        },
        {
          title: "数量",
          dataIndex: "number",
          scopedSlots: { customRender: "number" },
          align: "center",
          width: "10%",
        },
        {
          title: "单价（含税）",
          dataIndex: "unitPrice",
          scopedSlots: { customRender: "unitPrice" },
          align: "center",
          width: "18%",
        },
        {
          title: "金额（含税）",
          dataIndex: "money",
          scopedSlots: { customRender: "money" },
          align: "center",
          width: "15%",
        },
        {
          title: "税率/征收率",
          dataIndex: "taxRate",
          scopedSlots: { customRender: "taxRate" },
          align: "center",
          width: "15%",
        },
        {
          title: "税额",
          dataIndex: "taxAmount",
          scopedSlots: { customRender: "taxAmount" },
          align: "center",
          width: "15%",
        },
        {
          title: "操作",
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
          align: "center",
          fixed:'right',
          width: 150,
        },
      ],
      columns1: [ {
          title: "项目名称",
          dataIndex: "invoiceContentName",
          align: "center",
          width:  330,
        },
        
        {
          title: "规格",
          dataIndex: "model",
          scopedSlots: { customRender: "model" },
          align: "center",
          width: 260,
        },
        {
          title: "单位",
          dataIndex: "unit",
          scopedSlots: { customRender: "unit" },
          align: "center",
          width: "12%",
        },
        {
          title: "数量",
          dataIndex: "number",
          scopedSlots: { customRender: "number" },
          align: "center",
          width: "10%",
        },
        {
          title: "单价（含税）",
          dataIndex: "unitPrice",
          scopedSlots: { customRender: "unitPrice" },
          align: "center",
          width: "15%",
        },
        {
          title: "金额（含税）",
          dataIndex: "money",
          scopedSlots: { customRender: "money" },
          align: "center",
          width: "15%",
        },
        {
          title: "税率/征收率",
          dataIndex: "taxRate",
          scopedSlots: { customRender: "taxRate" },
          align: "center",
          width: "15%",
        },
        {
          title: "税额",
          dataIndex: "taxAmount",
          scopedSlots: { customRender: "taxAmount" },
          align: "center",
          width: "15%",
        },
      ],
      selcontent:[],
      remarks:'',
      editingKey: "",
      sellerList:[],
      // modelList:[{id:0,name:'空白(发票上空白，不显示内容)'},{id:1,name:'无(发票上显示为"无")'}]
      modelList:['空白(发票上空白，不显示内容)','无(发票上显示为"无")']
  };
},
props: {
  sellerId: {
    type: String,
    defalut: "",
  },
  data:{
      type: Array,
      defalut: [],
  },
  // inviceIndex:{
  //     type:String,
  //     defalut:""
  // }
},
watch:{
  sellerId(){
    this.getSellerList()
    if(!this.$route.query.id){
      this.data = [];
      this.cacheData = [];
    }      
  },
},  
mounted(){
  this.getSellerList()
},
methods: {
  formatAmount(amount) {
  // 将金额转换为字符串
  let amountStr = amount.toString();

  // 检查是否包含小数点
  if (amountStr.includes('.')) {
      const [integerPart, decimalPart] = amountStr.split('.');

      // 如果小数点前的数字超过12位，则四舍五入为整数
      if (integerPart.length > 12) {
          return Math.round(amount);
      }

      // 如果小数部分超过12位，则截取前12位
      if (decimalPart.length > 12) {
          amountStr = integerPart + '.' + decimalPart.substring(0, 12);
      }
  }

  // 如果金额为12位，则返回金额
  return amountStr;
},
  // 项目名称
  getSellerList() {
    if (this.sellerId) {
      request(
        process.env.VUE_APP_API_BASE_URL +
        "sfa/invoice/content/list/" +
        this.sellerId,
        "get"
      ).then((res) => {
        this.sellerList = res.data.data;
      });
    }
  },
  add() {
    let timestamp = new Date().getTime(); // 获取当前时间戳
    this.data.push({
      frontKey: timestamp,
      contentId: null,
      unit: "",
      number: "",
      unitPrice: "",
      money: "",
      taxRate: "",
      taxAmount: "",
      editable: true,
    });
    this.cacheData = [...this.data];
    this.$set(this, 'data', [...this.data]);
    console.log(this.data, 'data')
    console.log(this.cacheData, 'cacheData')
  },
  //  选择项目 
  selectChange(value, frontKey, taxRate) {
    const newData = [...this.data];
    const target = newData.find((item) => frontKey === item.frontKey);
    if (target) {
      target["contentId"] = value;
      target["taxRate"] = taxRate.taxRate
      this.data = newData;
    }
  },
  selectModelChange(value, frontKey) {
    value = value.join(",");
    console.log(value,'val')
    console.log(frontKey,'frontKey')
    const newData = [...this.data];
    const target = newData.find((item) => frontKey === item.frontKey);
    console.log(target,'target')
    if (target) {
      target["model"] = value;
      this.data = newData;
    }
  },
  handleChange(value, frontKey, col) {
    const newData = [...this.data];
    const target = newData.find((item) => frontKey === item.frontKey);
    if (target) {
      target[col] = value;
      this.data = newData;
      target.editable = true;
    }
  },
  editCol(frontKey) {
    if (this.$route.query.isDetail) {
      return false;
    }
    const newData = [...this.data];
    const target = newData.find((item) => frontKey === item.frontKey);
    this.editingKey = frontKey;
    if (target) {
      target.editable = true;
      this.data = newData;
    }
    this.cacheData = this.data.map((item) => ({ ...item }));
  },
  save(frontKey) {
    for (let i = 0; i < this.data.length; i++) {
      if(frontKey == this.data[i].frontKey) {
        if (!this.data[i].contentId || !this.data[i].money || !this.data[i].unit) {
          this.$message.error('请补全开票明细！');
          return false;
        }
      }
      // if(!this.data[i].money) {
      this.data[i].unitPrice = this.formatAmount(this.data[i].money / this.data[i].number);
      // this.data[i].money = this.data[i].money.toFixed(2)
      // }
      // if(!this.data[i].taxAmount) {
      this.data[i].taxAmount = this.data[i].money * (this.data[i].taxRate / 100)
      this.data[i].taxAmount = this.data[i].taxAmount.toFixed(2)
      // }
    }
    const newData = [...this.data];
    const newCacheData = [...this.cacheData];
    console.log(this.cacheData,'this.cacheData')
    const target = newData.find((item) => frontKey === item.frontKey);
    if (!target.model) {
      target.model = this.modelList[0];
    }
    const targetCache = newCacheData.find((item) => frontKey === item.frontKey);
    if (target && targetCache) {
      delete target.editable;
      this.data = newData;
      Object.assign(targetCache, target);
      this.cacheData = newCacheData;
    }
    this.editingKey = "";
    this.$emit("sendCententList", this.data);
  },
  del(frontKey) {
    const newData = [...this.data];
    const index = newData.findIndex((item) => frontKey === item.frontKey); // 找到目标行的索引
    this.editingKey = "";
    if (index !== -1) {
      // 如果找到目标行
      newData.splice(index, 1); // 从 newData 数组中移除目标行
      this.data = newData; // 更新数据源
    }
    this.$emit("sendCententList", this.data);
  },
  cancel(frontKey) {
    const newData = [...this.data];
    const target = newData.find((item) => frontKey === item.frontKey);
    this.editingKey = "";
    if (target) {
      Object.assign(
        target,
        this.cacheData.find((item) => frontKey === item.frontKey)
      );
      delete target.editable;
      this.data = newData;
    }
  },
},
};
</script>


<style>
.isNoShow {
  display: none;
}
</style>